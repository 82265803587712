<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div uk-flex>
        <h5 class="uk-text-bold">
          Data Kandang
        </h5>
      </div>
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kode Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.cage_code"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="kode_peternakan"
                :class="{'uk-form-danger': errors.has('kode_peternakan')}"
              >
              <div
                v-show="errors.has('kode_peternakan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('kode_peternakan') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.name"
                v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                class="uk-input"
                type="text"
                name="nama_kandang"
                :class="{'uk-form-danger': errors.has('nama_kandang')}"
              >
              <div
                v-show="errors.has('nama_kandang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('nama_kandang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Peternakan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <multiselect 
                  id="farm"
                  v-model="farmChoose"
                  v-validate="'required'"
                  label="name"
                  name="peternakan"
                  track-by="farm_code"
                  placeholder="Pilih..."
                  open-direction="bottom"
                  :options="dataFarms"
                  :searchable="true"
                  :loading="isLoading"
                  :close-on-select="closeOnSelect"
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectFarm"
                  @remove="onRemoveFarm"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('peternakan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('peternakan') }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="statusSwitch"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="statusSwitch"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Anak Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="cage_staff" 
                v-model="cageStaffChoose" 
                label="name"
                name="anak_kandang" 
                track-by="code" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="dataCageStaffs" 
                :searchable="true" 
                :loading="isLoading"
                :disabled="isDisabledSelect"
                :close-on-select="closeOnSelect" 
                :max-height="500" 
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('anak_kandang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('anak_kandang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pengawas Lapangan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="field_supervisor" 
                v-model="fieldSupervisorChoose" 
                v-validate="'required'"
                label="name" 
                name="pengawas_lapangan" 
                track-by="code" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="dataFieldSupervisors" 
                :searchable="true"
                :loading="isLoading"
                :disabled="isDisabledSelect" 
                :close-on-select="closeOnSelect" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('pengawas_lapangan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('pengawas_lapangan') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Cabang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="branch" 
                v-model="branchChoose" 
                v-validate="'required'"
                label="name" 
                name="cabang" 
                track-by="name" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="dataBranches" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('cabang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('cabang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kota/Kabupaten
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="city" 
                v-model="cityChoose" 
                v-validate="'required'"
                label="city"
                name="kota"
                track-by="id" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="dataCities" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="closeOnSelect" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('kota')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('kota') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Alamat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <textarea
                v-model="formCage.address"
                :class="{'uk-textarea': true}"
                rows="5"
              />
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Luas Lahan (m2)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.total_area"
                v-validate="'required|numeric'"
                class="uk-input"
                type="text"
                name="luas_lahan"
                :class="{'uk-form-danger': errors.has('luas_lahan')}"
              >
              <div
                v-show="errors.has('luas_lahan')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('luas_lahan') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tipe Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="cage_category" 
                v-model="cageCategoryChoose" 
                v-validate="'required'"
                label="name" 
                name="tipe_kandang" 
                track-by="id" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :options="dataCageCategorys" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
                @select="onSelectCageCategory"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('tipe_kandang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('tipe_kandang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Daya Tampung (ekor)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.chick_capacity"
                class="uk-input"
                type="text"
                name="daya_tampung"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tingkat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.floor"
                class="uk-input"
                type="text"
                name="floor"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Panjang (m)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.height"
                class="uk-input"
                type="text"
                name="width"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Lebar (m)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.width"
                class="uk-input"
                type="text"
                name="wide"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kipas
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.fan"
                class="uk-input"
                type="text"
                name="fan"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pemanas
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formCage.heater"
                class="uk-input"
                type="text"
                name="heating"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pajak Penghasilan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="income_tax" 
                v-model="tax_choose" 
                v-validate="'required'"
                label="name" 
                name="income_tax" 
                track-by="name" 
                placeholder="Pilih pajak penghasilan..." 
                open-direction="bottom" 
                :options="tax_list" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('income_tax')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('income_tax') }}
              </div>
            </div>
          </div>
          <div
            v-if="show_npwp"
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              NPWP
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="formCage.npwp"
                  v-mask="'##.###.###.#-###.###'"
                  v-validate="{ required: show_npwp, min:20, max:20 }"
                  class="uk-input"
                  type="text"
                  name="npwp_field"
                  placeholder="Masukkan Nomor NPWP"
                  :class="{'uk-form-danger': errors.has('npwp_field')}"
                >
                <div
                  v-show="errors.has('npwp_field') || customError != ''"
                  class="uk-text-small uk-text-danger"
                >
                  {{ customError == '' ? errors.first('npwp_field') : customError }}
                </div>
              </div>
            </div>
          </div>




          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Akuisisi
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <multiselect 
                id="acquisition_id" 
                v-model="acquisitionIdChoose" 
                label="acquisition_name" 
                name="acquisition_id" 
                track-by="id" 
                placeholder="Pilih..." 
                open-direction="bottom" 
                :custom-label="customLabel"
                :options="dataAcquisition" 
                :searchable="true"
                :loading="isLoading" 
                :close-on-select="true" 
                :max-height="500"
                :show-no-results="true"
                :disabled="!isAcquisitionEditable || !cageCategoryChoose "
                @search-change="handleDataAcquisition"
              >
                <span slot="noResult">Oops! Data tidak ditemukan.</span>
              </multiselect>
              <div
                v-show="errors.has('acquisition_id')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('acquisition_id') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger"
          type="button"
          @click="goToBack"
        >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-left"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'
import { dateString } from '@/utils/formater'
import { STATUS } from '@/utils/constant'

export default {
  props: {
    dataCage: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      statusSwitch: true,
      status: STATUS,
      customError: '',
      images: PREFIX_IMAGE,
      isLoading: true,
      closeOnSelect: true,
      isDisabledSelect: false,
      isAcquisitionEditable: false,
      formCage: {
        name: '',
        cage_code: '',
        cage_staff: '',
        field_supervisor: '',
        chick_source: '',
        farm_id: '',
        total_area: '',
        height: '',
        width: '',
        cage_category_id:'',
        floor: '',
        heater: '',
        fan: '',
        chick_capacity: '',
        cage_id: '',
        branch_id: '',
        city_id: '',
        address: '',
        acquisition_id: '',
        npwp: null
      },
      tax_list: [
        {
          name: 'Pribadi (PPh 21) dengan NPWP',
          show: true,
          value: "2.5"
        },
        {
          name: 'Pribadi (PPh 21) tanpa NPWP',
          show: false,
          value: '3'
        },
        {
          name: 'Badan (PPh 23) dengan NPWP',
          show: true,
          value: '2'
        },
        {
          name: 'Badan (PPh 23) tanpa NPWP',
          show: false,
          value: '4'
        }
      ],
      show_npwp: false,
      tax_choose: '',
      farmChoose: {},
      cageStaffChoose: {},
      fieldSupervisorChoose: {},
      cageCategoryChoose: {},
      branchChoose: {},
      cityChoose: {},
      acquisitionIdChoose:{},
      dataFarms: [],
      dataCageStaffs: [],
      dataFieldSupervisors: [],
      dataCageCategorys: [],
      dataBranches: [],
      dataCities: [],
      dataAcquisition: [],
      debounce: null
    }
  },
  computed: {
    customLabel() {
      return (option) => {
        if (option && option.acquisition_name) {
          const truncatedText = option.acquisition_name.length > 20 ? option.acquisition_name.slice(0, 10) + "..." : option.acquisition_name
          return `${truncatedText}`
        }
        return ""
      }
    },
    ...mapGetters({
      cageDetail: 'cage/cage',
      farms: 'farm/farms',
      cageCategorys: 'cageCategory/cageCategorys',
      userCageStaffs: 'user/userCageStaffs',
      userFieldSupervisors: 'user/userFieldSupervisors',
      branches: 'branch/branches',
      cities: 'city/cities',
      dataListAcquisition: 'acquisition/dataListForCage',
      dataMeta: 'acquisition/dataMeta'
    })
  },
  watch: {
    acquisitionIdChoose(){
      this.formCage.acquisition_id = this.acquisitionIdChoose.id
    },
    async 'formCage.npwp'(){
      if(this.formCage.npwp.length < 20){
        return this.customError = 'The tax_id_number field must be at least 15 characters'
      } else {
        return this.customError = ''
      }
    },
    tax_choose() {
      this.show_npwp = this.tax_choose ? this.tax_choose.show : false
    },
    async dataCage() {
      const getData = this.dataCage.data
      this.isAcquisitionEditable = getData.acqusition_editable
      this.statusSwitch = getData.status
      this.formCage = {
        cage_id: getData.id,
        cage_code: getData.cage_code,
        name: getData.name,
        total_area: getData.total_area,
        address: getData.address
      }

      await Promise.all([
        this.setSelectedFarm(),
        this.setSelectedFieldSupervisor(),
        this.setSelectedBranch(),
        this.setSelectedCity(),
        this.setSelectedCageCategory(),
        this.handleDataAcquisition({})
      ]),
      this.isLoading = false
      this.formCageNpwp()
    },
    async cageCategoryChoose() {
      const getData = this.dataCage.data
      this.formCage = {
        id: getData.id,
        cage_code: getData.cage_code,
        name: getData.name,
        total_area: getData.total_area,
        address: getData.address,
        chick_capacity: this.cageCategoryChoose.capacity,
        floor: this.cageCategoryChoose.floor,
        height: this.cageCategoryChoose.height,
        width: this.cageCategoryChoose.width,
        fan: this.cageCategoryChoose.fan,
        heater: this.cageCategoryChoose.heater
      }
      this.handleDataAcquisition()
    },
    'cageCategoryChoose.name'(a, b){
      if(!a || a!=b ){
        this.acquisitionIdChoose = ''
      }
    }
  },
  methods: {
    async formCageNpwp(){
      this.tax_choose = this.tax_list.find(i => i.value == this.dataCage.data.income_tax)
      this.formCage.npwp = this.dataCage.data.npwp
    },
    async onRemoveFarm () {
      this.cageStaffChoose = ''
      this.isDisabledSelect = true
    },
    async setSelectedFarm() {
      const getData = this.dataCage.data
      // COLLECT FOR SELECTED
      if (getData && getData.farm.id) {
        await Promise.all([
          this.getFarm({status:true, is_all: true}),
          this.handleFindCageStaff(getData.farm.id)
        ])
        this.dataFarms = this.farms ? this.farms : []
        this.farmChoose = { 
          city: getData.farm.city,
          city_id: getData.farm.city_id,
          farm_code: getData.farm.farm_code,
          id: getData.farm.id,
          join_date: getData.farm.join_date,
          name: getData.farm.name,
          status: getData.farm.status,
          total_cage: getData.farm.total_cage
        }
        this.cageStaffChoose = { id: getData.cage_staff_id, name: getData.cage_staff }
      }
    },
    async setSelectedFieldSupervisor() {
      const getData = this.dataCage.data
      // COLLECT FOR SELECTED
      if (getData.field_supervisor_id) {
        await this.handleFindFieldSupervisor()
        this.fieldSupervisorChoose = { id: getData.field_supervisor_id, name: getData.field_supervisor }
      }
    },
    async setSelectedBranch() {
      const getData = this.dataCage.data
      // COLLECT FOR SELECTED
      if (getData) {
        await this.getBranch({status:true, is_all: true})
        this.dataBranches = this.branches ? this.branches : []
        this.branchChoose = {id: getData.branch_id, name: getData.branch}
      }
    },
    async setSelectedCity() {
      const getData = this.dataCage.data
      // COLLECT FOR SELECTED
      if (getData) {
        await this.getCity({is_all: true})
        this.dataCities = this.cities ? this.cities : []
        this.cityChoose = {id: getData.city_id, city: getData.city}
      }
    },
    async setSelectedCageCategory() {
      const getData = this.dataCage.data
      // COLLECT FOR SELECTED
      if (getData) {
        await this.getCageCategory({status:true, is_all: true})
        this.dataCageCategorys = this.cageCategorys ? this.cageCategorys : []
        this.cageCategoryChoose = {
          capacity: getData.chick_capacity,
          fan: getData.fan,
          floor: getData.floor,
          heater: getData.heater,
          height: getData.height,
          id: getData.cage_category_id,
          name: getData.cage_category,
          width: getData.width
        }
      }
    },
    ...mapActions({
      getFarm: 'farm/getFarm',
      getCageCategory: 'cageCategory/getCageCategory',
      getUserCageStaff: 'user/getUserCageStaff',
      getUserFieldSupervisor: 'user/getUserFieldSupervisor',
      getBranch: 'branch/getBranch',
      getCity: 'city/getCity',
      getDataList: 'acquisition/getDataListForCage'
    }),
    ...mapMutations({
      setModalEdit: 'cage/SET_MODAL_EDIT',
      setDataMeta: 'acquisition/SET_DATA_META'
    }),
    handleDataAcquisition(value) {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(async () => {
        await this.getDataList({
          cage_category_id: this.cageCategoryChoose.id,
          acquisition_id: this.cageDetail.data.acquisition_id,
          acquisition_name: typeof value == 'object' ? '' : value
        })
        this.dataAcquisition = this.dataListAcquisition || []
        if(!this.isAcquisitionEditable){
          this.acquisitionIdChoose= {acquisition_id: this.cageDetail.data.acquisition_id, acquisition_name: this.cageDetail.data.acquisition_name}
        }
      }, 600)
    },
    onSelectFarm(val) {
      this.cageStaffChoose = ''
      this.handleFindCageStaff(val.id)
      this.isDisabledSelect = false
    },
    onSelectCageCategory(val) {
      this.formCage.chick_capacity = val ? val.capacity : ''
      this.formCage.floor = val ? val.floor : ''
      this.formCage.height = val ? val.height : ''
      this.formCage.width = val ? val.width : ''
      this.formCage.fan = val ? val.fan : ''
      this.formCage.heater = val ? val.heater : ''
    },
    async handleFindCageStaff (farmId) {
      await this.getUserCageStaff({role:'Anak Kandang', status:true, farm_id:farmId, is_all: true})
      this.dataCageStaffs = this.userCageStaffs ? this.userCageStaffs : []
    },
    async handleFindFieldSupervisor () {
      await this.getUserFieldSupervisor({role:'PPL', status:true, is_all: true})
      this.dataFieldSupervisors = this.userFieldSupervisors ? this.userFieldSupervisors : []
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.formCage.cage_staff = this.cageStaffChoose ? this.cageStaffChoose.id : ''
      this.formCage.field_supervisor = this.fieldSupervisorChoose ? this.fieldSupervisorChoose.id : ''
      this.formCage.farm_id = this.farmChoose ? this.farmChoose.id : ''
      this.formCage.branch_id = this.branchChoose ? this.branchChoose.id : ''
      this.formCage.city_id = this.cityChoose ? this.cityChoose.id : ''
      this.formCage.cage_category_id = this.cageCategoryChoose ? this.cageCategoryChoose.id : ''
      this.formCage.cage_id = this.formCage ? this.formCage.id : ''
      this.formCage.acquisition_id = this.formCage.acquisition_id ? this.formCage.acquisition_id : ''
      this.formCage.total_area = this.formCage.total_area.toString()
      this.formCage.chick_capacity = this.formCage.chick_capacity.toString()
      this.formCage.floor = this.formCage.floor.toString()
      this.formCage.height = this.formCage.height.toString()
      this.formCage.width = this.formCage.chick_capacity.toString()
      this.formCage.fan = this.formCage.fan.toString()
      this.formCage.heater = this.formCage.heater.toString()
      this.formCage.income_tax = this.tax_choose.value
      this.formCage.npwp = this.show_npwp ? this.formCage.npwp : null
      this.formCage.status = this.statusSwitch ? true : false

      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          window.UIkit.modal('#modal-save-confirm').show()
          this.setModalEdit(this.formCage)
        }
      })
    }
  }
}
</script>
