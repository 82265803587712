<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Ubah Data
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data-cage="cage" />
    <div id="container-modal-edit-cage" />
    <modal-out-confirm />
    <modal-save-confirm />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/kandang-ayam',
          title: 'Daftar Kandang Ayam'
        },
        {
          link: null,
          title: 'Ubah Kandang'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      cage: 'cage/cage'
    })
  },
  mounted() {
    this.getCageDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getCageDetail: 'cage/getCageDetail'
    })
  }
}
</script>
